import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  handleSuccess = () => {
    this.router.navigate(['/']);
  };

  handleRequestPassword = () => {
    this.router.navigate(['/forgot-password']);
  };
}
