// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA6tRUmfU9ol2BYCKSM7h2zGIth_pgExS0',
    authDomain: 'bsi-muskyhuntertv.firebaseapp.com',
    databaseURL: 'https://bsi-muskyhuntertv.firebaseio.com',
    projectId: 'bsi-muskyhuntertv',
    storageBucket: 'bsi-muskyhuntertv.appspot.com',
    messagingSenderId: '441993674423',
    appId: '1:441993674423:web:1877bc7063f8e5fb775732',
    measurementId: 'G-JVM3M766SH'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
