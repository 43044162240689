import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(private httpClient: HttpClient) {}

  downloadFile = (url: string) => {
    const queryParts = url.split('?');
    const urlParts = queryParts[0].split('/');
    const fileParts = urlParts[urlParts.length - 1].split('%2F');
    const fileName = fileParts[fileParts.length - 1];
    return this.httpClient
      .get(url, {
        responseType: 'blob'
      })
      .pipe(
        tap(data => {
          if (data) {
            console.log('data', data);
            saveAs(data, fileName);
          }
        })
      );
  };
}
