import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DownloadService } from 'src/app/services/download.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user$: Observable<firebase.User>;
  catchData: any;
  catches: any[] = [];
  uid: string;

  public masonryOptions: NgxMasonryOptions = {
    horizontalOrder: true
  };

  constructor(
    private downloadService: DownloadService,
    private afAuth: AngularFireAuth,
    private db: AngularFirestore
  ) {
    this.afAuth.user
      .pipe(
        take(1),
        switchMap(user => {
          this.uid = user.uid;

          return this.db
            .collection('user-catches')
            .doc(this.uid)
            .valueChanges()
            .pipe(
              map(data => {
                this.catchData = data;
                if (this.catchData) {
                  const results = Object.keys(this.catchData).map(
                    key => this.catchData[key]
                  );
                  this.catches = _.sortBy(results, 'dateTime').reverse();
                }
                return [];
              })
            );
        })
      )
      .subscribe();
  }

  ngOnInit() {}

  handleDownload = (url: string) => {
    const download = this.downloadService.downloadFile(url);
    download.subscribe();
  };

  handleDelete = async (catchId: string) => {
    if (this.catchData[catchId]) {
      delete this.catchData[catchId];
      await this.db
        .collection('user-catches')
        .doc(this.uid)
        .set(this.catchData);
    }
  };
}
