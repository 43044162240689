import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { DownloadService } from 'src/app/services/download.service';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.css']
})
export class ImageCardComponent implements OnInit {
  @Input() catch: any;
  @Output() download = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();

  catchDateString: string;

  constructor(private downloadService: DownloadService) {}

  ngOnInit() {
    this.catchDateString = moment(this.catch.dateTime).format(
      'MM/DD/YYYY h:mm a'
    );
  }

  handleDownload(url: string) {
    this.download.emit(url);
  }

  handleDelete(catchId: string) {
    this.delete.emit(catchId);
  }
}
