import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-log-entry',
  templateUrl: './log-entry.component.html',
  styleUrls: ['./log-entry.component.css']
})
export class LogEntryComponent {
  catch$: Observable<any>;
  formData: FormGroup;
  uid: string;

  constructor(
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private formBuilder: FormBuilder,
    private location: Location
  ) {
    this.catch$ = this.afAuth.user.pipe(
      switchMap(user => {
        this.uid = user.uid;
        return this.route.paramMap.pipe(
          switchMap((params: ParamMap) => {
            const catchId = params.get('catchId');
            console.log('catchId', catchId);
            return this.db
              .collection('user-catches')
              .doc(user.uid)
              .get()
              .pipe(
                map(snapshot => {
                  const data = snapshot.data();
                  if (data) {
                    this.formData.patchValue(data[catchId]);
                    return data[catchId];
                  }
                  return {};
                })
              );
          })
        );
      })
    );

    this.formData = this.formBuilder.group({
      catchId: [null],
      imageUrl: [null],
      caughtBy: [null],
      length: [null],
      girth: [null],
      weight: [null],
      dateTime: [null],
      lake: [null],
      bottomType: [null],
      depth: [null],
      structure: [null],
      state: [null],
      latitude: [null],
      longitude: [null],
      lureType: [null],
      lureName: [null],
      lureColor: [null],
      presentation: [null],
      speed: [null],
      moonPhase: [null],
      percentVisible: [null],
      moonEvent: [null],
      minutes: [null],
      beforeAfter: [null],
      skyConditions: [null],
      pressure: [null],
      pressureTrend: [null],
      windDirection: [null],
      windSpeed: [null]
    });
  }

  handleBack = () => {
    this.location.back();
  };

  handleSave = async () => {
    const data = this.formData.value;
    await this.db
      .collection('user-catches')
      .doc(this.uid)
      .update({
        [data.catchId]: data
      });
    this.handleBack();
  };
}
