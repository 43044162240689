import { Component } from '@angular/core';
import { FirebaseAuth } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  user$: Observable<firebase.User>;
  loggedIn: boolean;

  constructor(private afAuth: AngularFireAuth) {
    this.user$ = this.afAuth.user.pipe(
      tap(user => {
        this.loggedIn = !!user && !!user.uid;
      })
    );
  }

  handleSignout = () => {
    this.afAuth.auth.signOut();
    window.location.reload();
  };
}
